'use strict';

angular.module('webPartnerPortalApp')
  .controller('UserModalCtrl', ['user', 'newUser', 'roles', '$http', '$modalInstance', function(user, newUser, roles, $http, $modalInstance) {
    var vm = this;
    vm.user = angular.copy(user);
    vm.newUser = newUser;
    vm.roles = roles;
    if (newUser) {
      vm.user.portal_user_role_id = 2;
    } else {
      vm.password = {};
    }
    vm.errorMsg = '';
    vm.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
    vm.submit = function () {
      var method = newUser ? 'POST' : 'PUT';
      $http({
        method: method,
        url: 'api/user',
        data: vm.user
      }).then(function(){
        $modalInstance.close();
      }, function(err){
        vm.errorMsg = err.body.message;
      });
    };
  }]);
